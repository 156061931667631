



















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CiTable, {
    ITableData, ITableConfig, ICell, DATA_TYPE, IIndicators,
} from '@/modules/common/components/ci-table';
import CalendarEventsContainer from '@/modules/events/components/calendar-events-container.vue';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import HotelRooms from '@/modules/common/interfaces/hotelRooms.interface';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import PriceFilter from '@/modules/common/filters/price.filter';
import ASSESSMENTS_TYPES from '@/modules/common/constants/assessments-types.constant';
import Day from '@/modules/common/types/day.type';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import RatesService, { RatesServiceS } from '../../rates.service';
import RatesFiltersService, { RatesFiltersServiceS } from '../../rates-filters.service';
import RatesDocumentItemModel from '../../models/rates-document-item.model';
import RatesDocumentModel from '../../models/rates-document.model';
import RatesTableTooltip from './table-tooltip.vue';

const BOOKING_BASIC_ICON = require('@/modules/common/assets/booking-basic.svg');

@Component({
    components: {
        CiTable,
        RatesTableTooltip,
    },
    filters: {
        PriceFilter,
    },
})
export default class RatesTable extends Vue {
    @Inject(RatesServiceS) private ratesService!: RatesService;
    @Inject(HotelsServiceS) private hotelsService!: HotelsService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(MealTypesServiceS) private mealTypesService!: MealTypesService;

    public disabledColumns: string[] = [];
    public tooltipDay: Day = 1;
    public tooltipFocusElement: HTMLElement | null = null;
    public tooltipHotelName: string = '';
    public tooltipPreviewValues: { [k: string]: any } = {};
    public tooltipIndicators: IIndicators = {
        mealType: false,
        numberOfGuests: false,
        cancellation: false,
        losRestriction: false,
        occupancy: false,
    };

    transformDate(day: number): string {
        const { month, year } = this.documentFiltersService.storeState.settings;
        const d = new Date(year, month, day);
        const dayName = d.toLocaleDateString('en-US', { weekday: 'short' }).toLowerCase();

        return `${this.$t(dayName)} ${day}/${month + 1 < 10 ? 0 : ''}${month + 1}`;
    }

    getRoom(day: Day, hotelId: number) {
        return this.ratesService.getRoom(day, hotelId);
    }

    demand(day: Day) {
        const demandValue = this.ratesService.getDemand(day);
        return demandValue ? this.formatPercent(demandValue, 2) : '';
    }

    occupancy(day: Day) {
        const occupancyValue = this.ratesService.getOccupancy(day);
        if (!occupancyValue) return '';
        return this.formatPercent(occupancyValue * 100, 2);
    }

    medianPrice(day: Day) {
        const price = this.ratesService.getMedianPrice(day);
        return price ? PriceFilter(price) : '';
    }

    myHotelPrice(day: Day) {
        if (this.ratesService.isLoading) return null;

        const { currentHotelId } = this.userService;

        const { data } = this.ratesService;
        if (day && currentHotelId) {
            const { exchangeRate } = (data || {}) as RatesDocumentModel;

            return this.ratesService.getPrice(day, currentHotelId)! * exchangeRate;
        }
        return null;
    }

    getDiff(day: Day) {
        const compsetPrice = this.ratesService.getCompsetPrice(day);
        const myHotelPrice = this.myHotelPrice(day);

        if (!myHotelPrice || !compsetPrice) {
            return {
                value: '',
                color: '',
            };
        }

        const isBadColor = this.ratesService.getTableAssessment(compsetPrice, day) === ASSESSMENTS_TYPES.BAD;
        const result = {
            value: '',
            color: isBadColor ? 'red' : 'grey',
        };

        if (this.showPriceDiff) {
            const diffInNumber = Math.abs(myHotelPrice - compsetPrice);
            result.value = diffInNumber ? `${this.currency} ${PriceFilter(diffInNumber)}` : '';

            return result;
        }

        const diffInPercent = this.ratesService.getCompetitionPercent(day);
        result.value = diffInPercent ? `${this.formatPercent(diffInPercent * 100, 0)}%` : '';

        return result;
    }

    formatPercent(value: number | string, maxFixed: number = 2, minFixed: number = 0) {
        return Number(value).toLocaleString('en-US', { maximumFractionDigits: maxFixed, minimumFractionDigits: minFixed });
    }

    priceColor(day: Day, price: number) {
        const assessmentType = price ? this.ratesService.getTableAssessment(price, day) : null;

        if (assessmentType === ASSESSMENTS_TYPES.BAD) {
            return '#E7472D';
        }

        if (assessmentType === ASSESSMENTS_TYPES.GOOD) {
            return '#01B875';
        }

        return null;
    }

    getRoomData(day: Day, rooms: HotelRooms, hotelId: number) {
        if (!rooms[hotelId]) return null;

        const data = {
            rank: '',
            price: '',
            style: {},
            hasPrice: false,
            isBasic: false,
        };

        if (this.ratesService.isOutOfRange()) {
            data.price = 'Out Of Range';
            data.style = { color: 'grey' };
            return data;
        }

        if (this.ratesService.isNoData(day)) {
            data.price = 'No Data';
            data.style = { color: 'grey' };
            return data;
        }

        if (this.ratesService.isNA(day, hotelId)) {
            data.price = 'N/A';
            data.style = { color: 'grey' };
            return data;
        }

        if (this.ratesService.isSoldOut(day, hotelId)) {
            data.price = 'Sold Out';
            data.style = { color: 'grey' };
            return data;
        }

        const room = rooms[hotelId];
        const price = this.ratesService.switchPrice(rooms[hotelId]) as number;

        data.hasPrice = true;
        data.price = PriceFilter(price);
        data.rank = room ? `(${String(room!.priceRank || '')})` : '';
        data.style = { color: this.priceColor(day, price) };
        data.isBasic = !!room && room.isBasic;

        return data;
    }

    losRestriction(day: Day, hotelId: number) {
        const losRestriction = this.ratesService.getHotelLosRestriction(day, hotelId);
        if (!losRestriction) {
            return false;
        }

        return losRestriction;
    }

    indicators(day: Day, hotelId: number, hotelRooms: HotelRooms) {
        const indicators: IIndicators = {
            mealType: false,
            numberOfGuests: false,
            cancellation: false,
            losRestriction: false,
            occupancy: false,
        };

        const room = hotelRooms[hotelId];

        if (!room) {
            return indicators;
        }

        const mealType = this.mealTypesService.getMealType(room.mealTypeId) || null;

        const hasMealType = this.ratesService.hasRoomMealType(day, hotelId);
        if (hasMealType) {
            indicators.mealType = {
                name: 'Meal Type',
                value: mealType ? mealType.displayName : '',
                icon: 'icon-meal-3',
            };
        }

        const hasOccupancy = !this.ratesService.hasRoomSameOccupancy(day, hotelId);
        if (hasOccupancy) {
            indicators.occupancy = {
                name: 'Guest Number',
                value: String(room.occupancy),
                icon: 'icon-Guest-2-01',
            };
        }

        const hasCancelation = this.ratesService.hasRoomMultipleCancellation(day, hotelId);
        if (hasCancelation) {
            indicators.cancellation = {
                name: 'Cancellation',
                value: 'Multiple',
                icon: 'icon-cancelation2',
            };
        }
        const hasLosRestriction = this.losRestriction(day, hotelId);
        if (hasLosRestriction) {
            indicators.losRestriction = {
                name: 'Los restriction',
                value: String(hasLosRestriction),
                icon: 'icon-los2',
            };
        }

        return indicators;
    }

    handleRowClick(day: Day) {
        if (this.userService.currentHotelId) {
            const hotelId = String(this.userService.currentHotelId);

            this.$router.push({
                name: `${this.$route.name!}.day-rate`,
                params: { hotelId, day: String(day) },
            });
        }
    }

    setTooltipData(day: Day, focusElement: HTMLElement) {
        this.tooltipFocusElement = focusElement;
        this.tooltipDay = day;
    }

    resetTooltipData() {
        this.tooltipFocusElement = null;
    }

    updateDisabledColumnsList(disabledColumns: string[]) {
        this.disabledColumns = disabledColumns;
    }

    generatePreviewData(day: Day) {
        const params = {
            Events: () => ({
                component: CalendarEventsContainer,
                props: {
                    day,
                    emptyText: 'No events',
                },
            }),
            Demand: () => {
                const demand = this.demand(day);

                return {
                    value: demand ? `${demand}%` : 'No Data',
                };
            },
            OTB: () => {
                const occupancy = this.occupancy(day);

                return {
                    value: occupancy ? `${occupancy}%` : 'No Data',
                };
            },
            Median: () => {
                const median = this.medianPrice(day);
                const value = median
                    ? this.currency + median
                    : 'No Data';

                return {
                    value,
                };
            },
            Diff: () => ({
                value: this.getDiff(day).value,
            }),
        } as {
            [k: string]: () => {
                component?: any;
                props?: { [k: string]: any };
                value?: any,
            }
        };

        const entries = this.disabledColumns.map(key => {
            const value = params[key]();
            return [key, value];
        });

        return Object.fromEntries(entries);
    }

    get hotelId() {
        return +this.$route.params.hotelId || this.userService.currentHotelId!;
    }

    get showPriceDiff() {
        return this.ratesService.showDiff;
    }

    get currency(): string | null {
        const { currency } = this.ratesService;
        return currency ? this.helperService.currencySymbol(currency) : '';
    }

    get ratesTableConfig(): ITableConfig {
        return {
            height: '100%',
            width: '100%',
            cellSize: [{
                width: ['90px', '120px', '100px', '100px', '120px', '120px', '200px'],
                height: ['50px'],
            }, {
                width: ['200px'],
                height: ['50px'],
            }],
        };
    }

    get ratesTableData(): ITableData {
        const { currentHotelId } = this.userService;
        const competitors = this.ratesFiltersService.competitors || [];
        const { days } = this.documentFiltersService;

        if (!currentHotelId) {
            return [];
        }

        const rooms: HotelRooms[] = days.map(day => {
            const hotelRooms = competitors
                .reduce((acc, competitor) => {
                    const room = this.getRoom(day, competitor);
                    if (room) {
                        acc[competitor] = room;
                    }
                    return acc;
                }, {} as { [key: number]: RatesDocumentItemModel });

            const room = this.getRoom(day, currentHotelId);

            if (room) {
                hotelRooms[currentHotelId] = room;
            }

            return hotelRooms;
        });

        const handleCellHover = (day: Day, hotelId: number = currentHotelId) => (e: MouseEvent) => {
            const focusElement = e.currentTarget! as unknown as HTMLElement;
            this.tooltipIndicators = this.indicators(day, hotelId, rooms[day - 1]);
            this.tooltipHotelName = this.hotelsService.getHotelName(hotelId);
            this.tooltipPreviewValues = hotelId === currentHotelId
                ? this.generatePreviewData(day)
                : {};

            this.setTooltipData(day, focusElement);
        };

        return [
            {
                isSticky: true,
                columns: [
                    {
                        title: 'Date',
                        data: days.map(day => ({
                            value: this.transformDate(day),
                            onClick: () => this.handleRowClick(day),
                        })),
                    }, {
                        title: 'Events',
                        dataType: DATA_TYPE.EVENT,
                        data: days.map(day => ({
                            onClick: () => this.handleRowClick(day),
                        })),
                    }, {
                        title: 'Demand',
                        dataType: DATA_TYPE.DEMAND,
                        data: days.map(day => {
                            const demand = this.demand(day);
                            return {
                                value: demand ? `${demand}%` : '',
                                onClick: () => this.handleRowClick(day),
                            };
                        }),
                    }, {
                        title: 'OTB',
                        dataType: DATA_TYPE.OCCUPANCY,
                        data: days.map(day => {
                            const occupancy = this.occupancy(day);
                            return {
                                value: occupancy ? `${occupancy}%` : '',
                                onClick: () => this.handleRowClick(day),
                            };
                        }),
                    }, {
                        title: 'Median',
                        data: days.map(day => {
                            const median = this.medianPrice(day);
                            return {
                                value: median ? `${this.currency} ${median}` : '',
                                onClick: () => this.handleRowClick(day),
                            };
                        }),
                    }, {
                        title: 'Diff',
                        data: days.map(day => {
                            const { value, color } = this.getDiff(day);
                            return {
                                value,
                                style: { color },
                                onClick: () => this.handleRowClick(day),
                            };
                        }),
                    }, {
                        title: this.hotelsService.getHotelName(currentHotelId),
                        titleStyle: { color: '#00759e' },
                        dynamicColumns: ['Events', 'Demand', 'OTB', 'Median', 'Diff'],
                        data: days.map(day => {
                            const {
                                rank, price, hasPrice,
                                isBasic,
                            } = this.getRoomData(day, rooms[day - 1], currentHotelId) || {
                                rank: null,
                                price: this.ratesService.isNoData(day) ? 'No Data' : 'N/A',
                                hasPrice: false,
                                isBasic: false,
                            };

                            const indicators = this.indicators(day, currentHotelId, rooms[day - 1]);
                            const provider = this.ratesService.settings.provider === 'cheapest' && hasPrice
                                ? this.ratesService.getRoomProviders(day, currentHotelId)
                                : undefined;

                            return {
                                value: `${hasPrice ? `${this.currency} ` : ''}${price}`,
                                before: rank,
                                provider,
                                indicators,
                                img: isBasic ? BOOKING_BASIC_ICON : null,
                                onClick: () => this.handleRowClick(day),
                                onHover: handleCellHover(day),
                                onLeave: this.resetTooltipData.bind(this),
                            } as ICell;
                        }),
                    },
                ],
            },
            {
                columns: competitors.map(competitorId => ({
                    title: this.hotelsService.getHotelName(competitorId),
                    data: days.map(day => {
                        const {
                            rank, price, style, hasPrice,
                            isBasic,
                        } = this.getRoomData(day, rooms[day - 1], competitorId) || {
                            rank: null,
                            price: this.ratesService.isNoData(day) ? 'No Data' : 'N/A',
                            style: null,
                            hasPrice: false,
                            isBasic: false,
                        };
                        const indicators = this.indicators(day, competitorId, rooms[day - 1]);
                        const provider = this.ratesService.settings.provider === 'cheapest' && hasPrice
                            ? this.ratesService.getRoomProviders(day, competitorId)
                            : undefined;

                        return {
                            value: `${hasPrice ? `${this.currency} ` : ''}${price}`,
                            before: rank,
                            provider,
                            img: isBasic ? BOOKING_BASIC_ICON : null,
                            indicators,
                            style,
                            onClick: () => this.handleRowClick(day),
                            onHover: handleCellHover(day, competitorId),
                            onLeave: this.resetTooltipData.bind(this),
                        } as ICell;
                    }),
                })),
            },
        ];
    }
}
