






import { Component, Vue } from 'vue-property-decorator';
import RatesTable from '../components/table/rates-table.vue';
import TablePageLayout from '../components/table-page-layout.vue';

@Component({
    components: {
        RatesTable,
        TablePageLayout,
    },
})
export default class extends Vue {}
